import {
  ECONIA_COIN_ACCOUNT,
  MAINNET_SUSDE_WCOIN,
  MAINNET_USDC_WCOIN,
  MAINNET_USDT_WCOIN,
  TESTNET_USDT_WCOIN,
} from '@aries/aptos-defi/config';
import { BaseAsset } from '@aries/defi-toolkit/types';
import axios from 'axios';
import { compact, uniqBy } from 'lodash';
import { getCurrentAptosSDK } from '../aptos-sdk';
import IconCake from '../assets/pancakeswap.jpeg';

const TESTNET =
  'https://raw.githubusercontent.com/hippospace/aptos-coin-list/main/src/defaultList.testnet.json';
const MAINNET =
  'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/refs/heads/main/token-list.json';
export const fetchTokenList = async (
  isTestnet: boolean,
): Promise<BaseAsset[]> => {
  let tokenList: {
    id: string;
    coingeckoId: string;
    name: string;
    symbol: string;
    decimals: number;
    logoURI: string;
  }[] = [];
  if (isTestnet) {
    const { data } = await axios.get<
      {
        name: string;
        symbol: string;
        decimals: number;
        logo_url: string;
        token_type: {
          type: string;
        };
        coingecko_id: string;
      }[]
    >(TESTNET);

    tokenList =
      data.map(coin => {
        const baseInfo = {
          name: coin.name,
          symbol: coin.symbol,
          decimals: coin.decimals,
          logoURI: logoURIOverride[coin.symbol] ?? coin.logo_url,
          coingeckoId: coin.coingecko_id,
        };

        return {
          ...baseInfo,
          id: coin.token_type.type,
          coingeckoId:
            coingeckoIdOverride[coin.symbol] ?? coin.coingecko_id,
        };
      }) ?? [];
  } else {
    const { data } = await axios.get<
      {
        name: string;
        symbol: string;
        decimals: number;
        logoUrl: string;
        tokenAddress?: string | null;
        bridge?: string | null;
        coinGeckoId: string;
        panoraTags: string[];
      }[]
    >(MAINNET);

    tokenList = compact(
      data.map(
        ({
          name,
          symbol,
          decimals,
          logoUrl,
          coinGeckoId,
          tokenAddress,
          panoraTags,
          bridge,
        }) => {
          const overridedCoingeckoId =
            coingeckoIdOverride[tokenAddress || ''] ?? coinGeckoId;

          if (
            !tokenAddress ||
            panoraTags.includes('Banned') ||
            !overridedCoingeckoId ||
            panoraTags.includes('Unverified')
          ) {
            return undefined;
          }

          let overrideSymbol = symbol;
          if (bridge === 'LayerZero') {
            overrideSymbol = `z${symbol}`;
          }

          const baseInfo = {
            name,
            decimals,
            logoURI: logoURIOverride[symbol] ?? logoUrl,
          };

          return {
            ...baseInfo,
            symbol: overrideSymbol,
            id: tokenAddress,
            coingeckoId: overridedCoingeckoId,
          };
        },
      ) ?? [],
    );
  }

  if (isTestnet) {
    const coinStore =
      await getCurrentAptosSDK().coin.CoinInfo.all.fromAddress(
        ECONIA_COIN_ACCOUNT,
      );

    const coingeckoIdMap: Record<string, string> = {
      eAPT: 'tortuga-staked-aptos',
      eUSDC: 'usd-coin',
    };

    tokenList.push(
      ...coinStore.map(c => ({
        id:
          /(?:0x1::coin::CoinInfo<)(.*)(?:>)/g.exec(c.resourceType)?.[1] ??
          '',
        name: c.name,
        symbol: c.symbol,
        decimals: c.decimals.toNumber(),
        logoURI: logoURIOverride[c.symbol] ?? logoURIOverride.tUSDC,
        coingeckoId: coingeckoIdMap[c.symbol] ?? 'usd-coin',
      })),
    );
    tokenList.push({
      id: TESTNET_USDT_WCOIN,
      name: 'Tether USD',
      decimals: 6,
      symbol: 'USDT',
      logoURI: 'https://tether.to/images/logoCircle.png',
      coingeckoId: 'tether',
    });
  } else {
    tokenList.push({
      id: MAINNET_USDT_WCOIN,
      name: 'Tether USD',
      decimals: 6,
      symbol: 'USDT',
      logoURI: 'https://tether.to/images/logoCircle.png',
      coingeckoId: 'tether',
    });
    tokenList.push({
      id: MAINNET_USDC_WCOIN,
      name: 'USD Coin',
      decimals: 6,
      symbol: 'USDC',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/USDC.svg',
      coingeckoId: 'usd-coin',
    });
    tokenList.push({
      id: MAINNET_SUSDE_WCOIN,
      name: 'Ethena Staked USDe',
      decimals: 6,
      symbol: 'sUSDe',
      logoURI:
        'https://raw.githubusercontent.com/PanoraExchange/Aptos-Tokens/main/logos/sUSDe.png',
      coingeckoId: 'ethena-staked-usde',
    });
  }

  return uniqBy(tokenList, _token => _token.id);
};

const coingeckoIdOverride: Record<string, string> = {
  '0x84d7aeef42d38a5ffc3ccef853e1b82e4958659d16a7de736a29c55fbbeb0114::staked_aptos_coin::StakedAptosCoin':
    'tortuga-staked-aptos',
  '0x111ae3e5bc816a5e63c2da97d0aa3886519e0cd5e4b046659fa35796bd11542a::stapt_token::StakedApt':
    'amnis-staked-aptos-coin',
  '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::WBTC':
    'bitcoin',
  '0xe4ccb6d39136469f376242c31b34d10515c8eaaa38092f804db8e08a8f53c5b2::assets_v1::EchoCoin002':
    'gui-inu',
  '0xdd89c0e695df0692205912fb69fc290418bed0dbe6e4573d744a6d5e6bab6c13::coin::T':
    'solana',
};

const logoURIOverride: Record<string, string> = {
  CAKE: IconCake,
  eUSDC:
    'https://raw.githubusercontent.com/hippospace/aptos-coin-list/main/icons/USDC.svg',
  eAPT: 'https://raw.githubusercontent.com/hippospace/aptos-coin-list/main/icons/APT.webp',
};
