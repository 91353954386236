import { Asset, Balance, Big } from '@aries/defi-toolkit/types';
import React from 'react';

export interface Profile {
  id: string;
  name: string;
  summary: {
    apy: number;
    networth: string;
    isApyPositive: boolean;
    depositedValueUSD: string;
    borrowedValueUSD: string;
    availableMarginUSD: string;
    borrowingPowerPct: number; // 100 based
    rewardValueUSD: string;
    deposits: {
      asset: Asset;
      amount: string;
      valueUSDStr: string;
      needRedeposit?: boolean;
    }[];
    loans: {
      asset: Asset;
      amount: string;
      valueUSDStr: string;
      needReborrow?: boolean;
    }[];
    rewards: { asset: Asset; amount: string; valueUSDStr: string }[];
    emodeId?: string | undefined;
    riskFactorPct: number; // 100 based
  };
  claimRewards?: (rewardCoin: string) => Promise<boolean>;
}

export type NotifyType = 'info' | 'success' | 'warning' | 'error';
export interface Reserve {
  id: string;
  meta: {
    loanToValue: string;
    loanToValuePct: number;
    liquidationThreshold: string;
    liquidationBonus: string;
    lpCoinAddress: string;
    coinAddress: string;
    borrowLimit: string;
    borrowLimitNum: number;
    depositLimit: string;
    depositLimitNum: number;
    allowCollateral: boolean;
    allowWithdraw: boolean;
    borrowFeePct: number;
    withdrawFeePct?: number;
    borrowFactorPct?: number;
    optimalInterestRatePct: number;
    optimalUtilizationPct: number;
    maxInterestRatePct: number;
    minInterestRatePct: number;
    flashLoanFeePct: number;
    deprecated?: boolean;
    reserveInEmode?: boolean | undefined;
  };
  asset: Asset;
  summary: {
    marketCap: Balance;
    utilization: number;
    ofUser?: { totalMarginValueUSD: string };
  };
  deposit: {
    baseApy: {
      base: string;
      isPositive: boolean;
      num: number;
    };
    rewardApy?: {
      base: string;
      isPositive: boolean;
    };
    totalApy: {
      base: string;
      num: number;
      isPositive: boolean;
    };
    rewardAssets: (Asset & { rewardApy?: string; description?: string })[];
    balance: Balance;
    reachedDepositLimit?: boolean | undefined;
    ofUser?: {
      deposited: Balance;
      withdrawable: Big;
      depositable: Balance;
    };
  };
  borrow: {
    baseApy: {
      base: string;
      isPositive: boolean;
      num: number;
    };
    rewardApy?: {
      base: string;
      isPositive: boolean;
    };
    totalApy: {
      base: string;
      num: number;
      isPositive: boolean;
    };
    rewardAssets: (Asset & {
      rewardApy?: string;
      description?: string;
      isNegtive?: boolean;
    })[];
    balance: Balance;
    ofUser?: { available: Balance; borrowed: Balance };
    shouldExitEmode?: boolean | undefined;
  };
  wallet?: Balance;
  actions: {
    deposit: (amount: number) => Promise<boolean>;
    withdraw: (amount: number, isMax: boolean) => Promise<boolean>;
    borrow: (amount: number) => Promise<boolean>;
    repay: (amount: number, isMax: boolean) => Promise<boolean>;
    // addCollateral: (amount?: number) => Promise<boolean>;
    // removeCollateral: (amount?: number) => Promise<boolean>;
  };
  notify?: {
    content: string;
    type: NotifyType;
  };
}

export interface UnhealthyProfile {
  id: string;
  profile: string;
  borrowedValueUSD: number;
  depositedValueUSD: number;
  borrowedAssets: { balance: Balance; asset: Asset }[];
  depositedAssets: { balance: Balance; asset: Asset }[];
  riskPct: number; // 0-100;
  canLiquidate: boolean;
  liquidate: (from: Asset, to: Asset, amount: number) => void;
  predictReceive: (from: Asset, to: Asset, amount: number) => Balance;
  getMaxInput: (from: Asset, to: Asset) => Balance;
}

export interface Platform {
  name: string;
  collateral_ratio: string;
  platformTotal: {
    valueUSD: string;
    valueUSDNum: number;
  };
  reserves: {
    asset: Asset;
    deposit: {
      apy: {
        base: string;
        isPositive: boolean;
      };
      deposited: Balance;
    };
    borrow: {
      apy: {
        base: string;
        isPositive: boolean;
      };
      borrowed: Balance;
    };
  }[];
  reservesLoading: boolean;
}
export interface Token {
  asset: Asset;
  balance: Balance;
}

export interface LPToken {
  address: string;
  logo: React.ReactNode;
  platformName: string;
  platformURI: string;
  subTokens: [Token, Token];
  totalUSDNum: number;
  totalUSDValue: string;
}

export interface Farm {
  address: string;
  platformName: string;
  platformLogo: React.ReactNode;
  platformURI: string;
  subAssets: [Asset, Asset];
  balances: [Balance, Balance];
  lpSymbol: string;
  earningToken: Token;
  stakedUSDNum: number;
  stakedUSDValue: string;
  apr: number;
  totalUSDNum: number;
  totalUSDValue: string;
}

export interface BalanceHistory {
  timestamp: number;
  maxEquity: number;
  [profileName: string]: readonly [number, number] | number;
}

export const EMPTY_HISTORY: BalanceHistory = {
  timestamp: +new Date() - 10 ** 10,
  maxEquity: 0,
  'Main Account': [0, 0],
};

export type ActionType = 'deposit' | 'withdraw' | 'repay' | 'borrow';

export type Action = {
  profileName: string;
  actionType: ActionType;
  asset: Asset;
  balance: Balance;
  valueUSD: string;
  amount: string;
  txnDate: Date;
};
