import axios from 'axios';
import useSWR from 'swr';

interface EthenaYieldData {
  stakingYield: {
    lastUpdated: string;
    value: number;
  };
}

export const useUSDeStakingYield = () => {
  const { data, error, isValidating } = useSWR<number>(
    ['usdeStakingYield'],
    async () => {
      const response = await axios.get<EthenaYieldData>(
        'https://ethena.fi/api/yields/protocol-and-staking-yield',
      );
      return Number(response.data.stakingYield.value) || 0;
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      errorRetryCount: 0,
    },
  );

  return {
    usdeStakingYield: data,
    usdeStakingYieldError: error,
    isUSDeStakingYieldLoading: isValidating,
  };
};
