import { MAX_GAS_PER_TX, useTokenInfoHub } from '@aries/aptos-defi/common';
import { MAINNET_SUSDE_WCOIN } from '@aries/aptos-defi/config';
import { useWallet } from '@aries/aptos-defi/wallet';
import { getMax, getMin } from '@aries/defi-toolkit/utils';
import { createGlobalStore } from '@aries/shared/deps';
import Big from 'big.js';
import {
  compact,
  Dictionary,
  groupBy,
  isNil,
  keyBy,
  sortBy,
  sumBy,
} from 'lodash';
import { useMemo } from 'react';
import { useReserves } from '../reserves';
import { useUSDeStakingYield } from '../usde-staking-yields';
import { useProfileData } from './list';

export const useProfileByAddress = (walletAddress: string | undefined) => {
  const {
    profileList,
    hasInitialized,
    currentProfileKey,
    addProfile,
    initMainProfile,
    changeProfile,
    refreshCurrentProfile,
  } = useProfileData(walletAddress);

  const { reserveMap } = useReserves();
  const { tokenMap, emptyableTokenMap } = useTokenInfoHub();

  const { usdeStakingYield } = useUSDeStakingYield();

  const detailedProfiles = useMemo(
    () =>
      compact(
        profileList.map(({ name, address, value }) => {
          if (!value) return null;

          const {
            deposits,
            loans,
            rewards: rawRewards,
            profileEmode,
          } = value;
          const hasInitedRewards = !isNil(rawRewards);
          const rewards = rawRewards ?? [];

          const isReserveInEMode = (
            reserve: Pick<
              ReturnType<typeof useReserves>['reserves'][number],
              'rawReserve'
            >,
          ) => {
            return (
              !isNil(profileEmode) &&
              !!reserve.rawReserve.emodeIds?.includes(profileEmode.emodeId)
            );
          };

          const isReserveBorrowable = (
            reserve: Pick<
              ReturnType<typeof useReserves>['reserves'][number],
              'rawReserve'
            >,
          ) => {
            return (
              isNil(profileEmode) ||
              reserve.rawReserve.emodeIds?.includes(profileEmode.emodeId)
            );
          };

          const getLoanToValuePct = (
            reserve: Pick<
              ReturnType<typeof useReserves>['reserves'][number],
              'rawReserve' | 'loanToValuePct'
            >,
          ) => {
            return !isNil(profileEmode) && isReserveInEMode(reserve)
              ? profileEmode.loanToValue
              : reserve.loanToValuePct;
          };

          const getLiquidationThreshold = (
            reserve: Pick<
              ReturnType<typeof useReserves>['reserves'][number],
              'rawReserve' | 'liquidationThreshold'
            >,
          ) => {
            return !isNil(profileEmode) && isReserveInEMode(reserve)
              ? profileEmode.liquidationThreshold
              : reserve.liquidationThreshold;
          };

          const getLiquidationBonusPct = (
            reserve: Pick<
              ReturnType<typeof useReserves>['reserves'][number],
              'rawReserve' | 'liquidationBonusPct'
            >,
          ) => {
            return !isNil(profileEmode) && isReserveInEMode(reserve)
              ? profileEmode.liquidationBonusBips.div(100).toNumber()
              : reserve.liquidationBonusPct;
          };

          const depositRewardsMap: Dictionary<typeof rewards | undefined> =
            groupBy(
              rewards.filter(r => r.rewardCondition === 'DepositFarming'),
              r => r.reserveCoinAddress,
            );
          const depositList = sortBy(
            compact(
              deposits.map(deposit => {
                const { coinAddress, collateralAmount: collateralShare } =
                  deposit;

                const reserve = reserveMap[coinAddress];
                const tokenInfo = tokenMap[coinAddress];

                if (!reserve || !tokenInfo) return null;

                const collateralAsset =
                  reserve.shareToDeposit(collateralShare);

                const depositedValueUSD =
                  tokenInfo.toUSDValue(collateralAsset);

                const loanToValueRatio = getLoanToValuePct(reserve) / 100;

                const depositToMargin = (depositedValueUSD: number) => {
                  return depositedValueUSD * loanToValueRatio;
                };

                const liquidationThreshold =
                  getLiquidationThreshold(reserve);

                const depositToCollateralThreshold = (
                  depositedValueUSD: number,
                ) => {
                  return (depositedValueUSD * liquidationThreshold) / 100;
                };

                return {
                  asset: tokenInfo,
                  lamports: collateralAsset,
                  depositedValueUSD,
                  amount: tokenInfo.toAmountStr(collateralAsset),
                  valueUSDStr: tokenInfo.toUSDValueStr(collateralAsset),
                  amountNum: tokenInfo
                    .toAmount(collateralAsset)
                    .toNumber(),
                  marginValueUSD: depositToMargin(depositedValueUSD),
                  collateralThreshold:
                    depositToCollateralThreshold(depositedValueUSD),
                  needRedeposit:
                    reserve.rawFarms.filter(
                      f => f.rewardCondition === 'DepositFarming',
                    ).length >
                      (depositRewardsMap[coinAddress]?.length || 0) &&
                    hasInitedRewards,
                };
              }),
            ),
            v => -v.depositedValueUSD,
          );

          const borrowRewardsMap: Dictionary<typeof rewards | undefined> =
            groupBy(
              rewards.filter(r => r.rewardCondition === 'BorrowFarming'),
              r => r.reserveCoinAddress,
            );

          const loanList = sortBy(
            compact(
              loans.map(loan => {
                const { coinAddress, borrowedShare } = loan;

                const reserve = reserveMap[coinAddress];
                const tokenInfo = tokenMap[coinAddress];

                if (!reserve || !tokenInfo) return null;

                const shareAsset = reserve.shareToBorrow(borrowedShare);
                const { borrowFactorPct } = reserve;
                return {
                  asset: tokenInfo,
                  lamports: shareAsset,
                  amount: tokenInfo.toAmountStr(shareAsset),
                  valueUSD: tokenInfo.toUSDValue(shareAsset),
                  valueUSDWithFactor: tokenInfo.toUSDValue(
                    shareAsset.div(
                      borrowFactorPct === 100
                        ? 1
                        : (borrowFactorPct / 100) * 0.99, // Prevent from round error
                    ),
                  ),
                  valueUSDStr: tokenInfo.toUSDValueStr(shareAsset),
                  needReborrow:
                    reserve.rawFarms.filter(
                      f => f.rewardCondition === 'BorrowFarming',
                    ).length >
                      (borrowRewardsMap[coinAddress]?.length || 0) &&
                    hasInitedRewards,
                };
              }),
            ),
            v => -v.valueUSD,
          );

          const sumRewards = rewards.reduce(
            (acc, { rewardCoinAddress, claimableLamports }) =>
              acc.set(
                rewardCoinAddress,
                (acc.get(rewardCoinAddress) ?? Big(0)).add(
                  claimableLamports,
                ),
              ),
            new Map<string, Big>(),
          );
          const rewardList = sortBy(
            compact(
              Array.from(sumRewards.entries())
                .filter(([, claimableLamports]) => claimableLamports.gt(0))
                .map(([rewardCoinAddress, claimableLamports]) => {
                  const rewardCoinInfo =
                    emptyableTokenMap[rewardCoinAddress];
                  return rewardCoinInfo
                    ? {
                        asset: rewardCoinInfo,
                        lamports: claimableLamports,
                        amount:
                          rewardCoinInfo.toAmountStr(claimableLamports),
                        valueUSD:
                          rewardCoinInfo.toUSDValue(claimableLamports),
                        valueUSDStr:
                          rewardCoinInfo.toUSDValueStr(claimableLamports),
                      }
                    : null;
                }),
            ),
            v => -v.valueUSD,
          );

          const totalRewardUSD = rewardList.reduce(
            (sum, cur) => sum.add(cur.valueUSD),
            Big(0),
          );

          const totalDepositedUSD = depositList.reduce(
            (sum, cur) => sum.add(cur.depositedValueUSD),
            Big(0),
          );

          const totalLoanUSD = loanList.reduce(
            (sum, cur) => sum.add(cur.valueUSD),
            Big(0),
          );

          const totalLoanUSDWithFactor = loanList.reduce(
            (sum, cur) => sum.add(cur.valueUSDWithFactor),
            Big(0),
          );

          const totalMarginUSD = depositList.reduce(
            (sum, cur) => sum.add(cur.marginValueUSD),
            Big(0),
          );
          const totalCollateralThreshold = depositList.reduce(
            (sum, cur) => sum.add(cur.collateralThreshold),
            Big(0),
          );

          // Borrow power and risk
          const usedBorrowPowerPct = totalMarginUSD.eq(0)
            ? 100
            : totalLoanUSD.div(totalMarginUSD).mul(100).toNumber();

          const riskFactorPct = totalCollateralThreshold.eq(0)
            ? 0
            : totalLoanUSD.div(totalCollateralThreshold).toNumber();

          // APY
          const totalEarnPerYear = depositList.reduce((sum, cur) => {
            const reserve = reserveMap[cur.asset.id];
            if (!reserve) return sum;

            const apy = reserve.supplyApyPct.div(100).toNumber();
            const rewardApy = reserve.supplyRewardApyPct
              .div(100)
              .toNumber();
            return (
              sum +
              cur.depositedValueUSD *
                (apy +
                  rewardApy +
                  (cur.asset.id === MAINNET_SUSDE_WCOIN && usdeStakingYield
                    ? usdeStakingYield / 100
                    : 0))
            );
          }, 0);

          const totalLoanInterest = loanList.reduce((sum, cur) => {
            const reserve = reserveMap[cur.asset.id];
            if (!reserve) return sum;

            const apy = reserve?.borrowApyPct.div(100).toNumber();
            const rewardApy = reserve.borrowRewardApyPct
              .div(100)
              .toNumber();
            return (
              sum +
              cur.valueUSD *
                (apy -
                  rewardApy -
                  (cur.asset.id === MAINNET_SUSDE_WCOIN && usdeStakingYield
                    ? usdeStakingYield / 100
                    : 0))
            );
          }, 0);

          const netAsset = totalDepositedUSD
            .minus(totalLoanUSD)
            .toNumber();

          const totalApyPct =
            (netAsset === 0
              ? 0
              : (totalEarnPerYear - totalLoanInterest) / netAsset) * 100;

          const getWithdrawableAmount = (coinAddress: string) => {
            const deposit = depositList.find(
              d => d.asset.id === coinAddress,
            );

            const reserve = reserveMap[coinAddress];
            const tokenInfo = tokenMap[coinAddress];

            if (!deposit || !reserve || !tokenInfo) return Big(0);

            const loanToValueRatio = getLoanToValuePct(reserve) / 100;
            const { maxWithdrableLamports } = reserve;

            const minCollateral =
              loanToValueRatio === 0
                ? 0
                : totalLoanUSDWithFactor
                    .sub(
                      sumBy(
                        depositList.filter(
                          d => d.asset.id !== coinAddress,
                        ),
                        d => d.marginValueUSD,
                      ),
                    )
                    .div(loanToValueRatio)
                    .toNumber();

            const withdrawableAssetUnsafe =
              minCollateral <= 0
                ? deposit.amountNum
                : Math.max(
                    0,
                    (deposit.amountNum -
                      minCollateral / tokenInfo.priceUSDNum) *
                      0.99999, // Avoid rounding problem
                  );

            const withdrawableAmountUnsafe = tokenInfo.toLamports(
              withdrawableAssetUnsafe,
            );

            const withdrawable = getMin(
              withdrawableAmountUnsafe,
              maxWithdrableLamports,
            );

            return withdrawable;
          };

          const getBorrowableAmount = (coinAddress: string) => {
            const reserve = reserveMap[coinAddress];
            const tokenInfo = tokenMap[coinAddress];

            if (!reserve || !tokenInfo) return Big(0);

            if (
              !isNil(profileEmode) &&
              !reserve.rawReserve.emodeIds?.includes(profileEmode.emodeId)
            ) {
              return Big(0);
            }

            const { borrowFeePct, maxBorrowableLamports } = reserve;

            const { borrowFactorPct } = reserve;
            const availableMarginUSD = (depositList ?? [])
              .reduce((sum, cur) => sum.add(cur.marginValueUSD), Big(0))
              .sub(totalLoanUSDWithFactor ?? Big(0))
              // Incase of round problem
              .sub(10 ** (5 - tokenInfo.decimals));

            const borrowFeeFactor = borrowFeePct.div(100).add(1);
            const borrowFactor = borrowFactorPct / 100;
            const priceAfterFee = tokenInfo.price.mul(borrowFeeFactor);

            const borrowableCoins = priceAfterFee.eq(0)
              ? Big(0)
              : availableMarginUSD.div(priceAfterFee).mul(borrowFactor) ??
                Big(0);

            const willCost = borrowableCoins.mul(borrowFeeFactor);
            const cashAmount = tokenInfo.toAmount(maxBorrowableLamports);

            return getMax(
              Big(0),
              willCost.gte(cashAmount)
                ? cashAmount.div(borrowFeeFactor)
                : borrowableCoins,
            )
              .mul(0.95)
              .round(tokenInfo.decimals, Big.roundDown);
          };

          const getDepositableAmount = (
            coinAddress: string,
            walletLamports: Big,
          ) => {
            const reserve = reserveMap[coinAddress];
            const tokenInfo = tokenMap[coinAddress];

            if (!reserve || !tokenInfo) return Big(0);

            const depositable = walletLamports.minus(
              tokenInfo.symbol === 'APT' ? MAX_GAS_PER_TX * 2 : 0,
            );

            return getMax(
              Big(0),
              getMin(
                tokenInfo.toAmount(depositable),
                tokenInfo.toAmount(reserve.maxDepositableLamports),
              ),
            );
          };

          const depositByCoin = keyBy(depositList, d => d.asset.id);
          const loanByCoin = keyBy(loanList, d => d.asset.id);

          const getDepositedLamports = (coinAddress: string) => {
            return depositByCoin[coinAddress]?.lamports ?? Big(0);
          };

          const getBorrowedLamports = (coinAddress: string) => {
            Big.DP = 50;
            const loanLamport = loanByCoin[coinAddress]?.lamports;
            if (loanLamport) {
              return loanLamport.lt(0)
                ? loanLamport.round(0, Big.roundUp)
                : loanLamport.round(0, Big.roundDown).add(1);
            }

            return Big(0);
          };

          return {
            name,
            address,
            depositList,
            loanList,
            rewardList,
            totalRewardUSD,
            totalLoanUSD,
            totalDepositedUSD,
            totalMarginUSD,
            profileEmode,
            availableMarginUSD: totalMarginUSD.sub(totalLoanUSDWithFactor),
            networth: totalDepositedUSD.sub(totalLoanUSD),
            usedBorrowPowerPct: Number(usedBorrowPowerPct.toFixed(2)),
            borrowPower:
              usedBorrowPowerPct > 100
                ? 0
                : Number((100 - usedBorrowPowerPct).toFixed(2)),
            riskFactorPct: Number(
              ((riskFactorPct > 1 ? 1 : riskFactorPct) * 100).toFixed(2),
            ),
            totalApyPct,
            isReserveBorrowable,
            getWithdrawableAmount,
            getBorrowableAmount,
            getDepositableAmount,
            getDepositedLamports,
            getBorrowedLamports,
            getLoanToValuePct,
            getLiquidationThreshold,
            getLiquidationBonusPct,
          };
        }),
      ),
    [emptyableTokenMap, profileList, reserveMap, tokenMap],
  );

  const currentProfile = useMemo(() => {
    return (
      detailedProfiles?.find(i => i.address === currentProfileKey) ?? null
    );
  }, [detailedProfiles, currentProfileKey]);

  return {
    hasInitialized,
    profileList: detailedProfiles,
    profileLoading:
      profileList.find(p => p.address === currentProfileKey)?.loading ??
      false,
    currentProfileKey,
    currentProfile,
    initMainProfile,
    addProfile,
    changeProfile,
    refreshCurrentProfile,
  };
};

export const [useProfileHub, getProfileHub] = createGlobalStore(() => {
  const { walletAddress } = useWallet();
  return useProfileByAddress(walletAddress);
});

export const getCurrentProfile = () => {
  const profile = getProfileHub()?.currentProfile;
  if (!profile) {
    throw new Error('Get profile failed, please create your profile.');
  }

  return profile;
};
