/* eslint-disable @typescript-eslint/naming-convention */
import {
  getBalanceHub,
  useProviderHub,
  useTokenInfoHub,
} from '@aries/aptos-defi/common';
import {
  IconAnimeswap,
  IconAptosswap,
  IconAux,
  IconBasiq,
  IconCellana,
  IconCetus,
  IconDitto,
  IconEconia,
  IconHippo,
  IconLiquidswap,
  IconObric,
  IconPancakeswap,
  IconSushiswap,
  IconThala,
  IconTortuga,
} from '@aries/aptos-defi/common/assets/dex-icons';
import { Asset } from '@aries/defi-toolkit/types';
import { createStore } from '@aries/shared/deps';
import { retryPost } from '@aries/shared/utils/retry-post';
import axios from 'axios';

import { SwapMarket } from '../../interface';

import {
  MAINNET_SUSDE_FA,
  MAINNET_SUSDE_WCOIN,
  MAINNET_USDC_FA,
  MAINNET_USDC_WCOIN,
  MAINNET_USDT_FA,
  MAINNET_USDT_WCOIN,
} from '@aries/aptos-defi/config';
import { withSendTxNotify } from '@aries/aptos-defi/utils';
import Panora from '@panoraexchange/swap-sdk';
import { compact, isEmpty, last, uniqBy } from 'lodash';

const panoraClient = new Panora({
  apiKey:
    'vG.nsu9nXVWagTKyNL.7alppSnffzQYEEAvKg5##2Mr0zatujdQ+40h@Syw2ttFE',
});

type DexType =
  | 'Hippo'
  | 'Econia'
  | 'Liquidswap V0'
  | 'Basiq'
  | 'Ditto'
  | 'Tortuga'
  | 'Aptoswap'
  | 'Aux'
  | 'AnimeSwap'
  | 'Cetus'
  | 'PancakeSwap'
  | 'Obric'
  | 'CetusV2'
  | 'Thala'
  | 'Cellana'
  | 'SushiSwap';

const DEX_TYPE_PLATFORM: Record<
  DexType,
  { name: string; logo: (...params: any[]) => JSX.Element }
> = {
  Hippo: {
    name: 'Hippo',
    logo: IconHippo,
  },
  Econia: {
    name: 'Econia',
    logo: IconEconia,
  },
  Cellana: {
    name: 'Cellana',
    logo: IconCellana,
  },
  SushiSwap: {
    name: 'SushiSwap',
    logo: IconSushiswap,
  },
  'Liquidswap V0': {
    name: 'Liquidswap',
    logo: IconLiquidswap,
  },
  Basiq: {
    name: 'Basiq',
    logo: IconBasiq,
  },
  Ditto: {
    name: 'Ditto',
    logo: IconDitto,
  },
  Tortuga: {
    name: 'Tortuga',
    logo: IconTortuga,
  },
  Aptoswap: {
    name: 'Aptoswap',
    logo: IconAptosswap,
  },
  Aux: {
    name: 'Aux',
    logo: IconAux,
  },
  AnimeSwap: {
    name: 'AnimeSwap',
    logo: IconAnimeswap,
  },
  Cetus: {
    name: 'Cetus',
    logo: IconCetus,
  },
  PancakeSwap: {
    name: 'PancakeSwap',
    logo: IconPancakeswap,
  },
  Obric: {
    name: 'Obric',
    logo: IconObric,
  },
  CetusV2: {
    name: 'CetusV2',
    logo: IconCetus,
  },
  Thala: {
    name: 'Thala',
    logo: IconThala,
  },
};

const ARIES_WCOIN_TO_FA_MAP: Record<string, string | undefined> = {
  [MAINNET_USDT_WCOIN]: MAINNET_USDT_FA,
  [MAINNET_USDC_WCOIN]: MAINNET_USDC_FA,
  [MAINNET_SUSDE_WCOIN]: MAINNET_SUSDE_FA,
};

export const [usePanoraSwap, PanoraSwapProvider] = createStore(
  (): SwapMarket => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { provider } = useProviderHub();

    const { tokenList, tokenMap } = useTokenInfoHub();
    return {
      totalAssetList: tokenList,
      getRoutes: async (
        fromAsset: Pick<Asset, 'id'>,
        toAsset: Pick<Asset, 'id'>,
        amount: number,
        slippageUnSafe: number,
        _,
        walletAddress,
      ) => {
        if (!fromAsset.id || !toAsset.id) {
          return [];
        }

        const slippage = Math.min(Math.max(slippageUnSafe, 0), 50);

        const fromTokenAddress =
          ARIES_WCOIN_TO_FA_MAP[fromAsset.id] || fromAsset.id;
        const toTokenAddress =
          ARIES_WCOIN_TO_FA_MAP[toAsset.id] || toAsset.id;

        const quote = await panoraClient.ExactInSwapQuote({
          chainId: '1',
          fromTokenAddress,
          fromTokenAmount: amount.toString(),
          toTokenAddress,
          slippagePercentage: slippage.toString(),
          numberOfRoutes: '1',
          integratorFeePercentage: '0',
          transactionPayload: true,
          toWalletAddress: walletAddress,
        });

        return compact(
          quote.quotes.map((_quote, _index) => {
            try {
              const { txData } = _quote;

              if (!txData) {
                return undefined;
              }

              const routes = _quote.route as {
                routeTaken: {
                  dexName: DexType;
                  fromToken: { symbol: string };
                  toToken: { symbol: string };
                  percentage: number;
                }[][];
              }[];

              return {
                id: `route.${_index}`,
                name: routes[0].routeTaken
                  .map(v => v.map(s => s.dexName).join('/'))
                  .join(' × '),
                dexes: routes.slice(0, 4).map(route =>
                  uniqBy(
                    route.routeTaken.flatMap(v => {
                      return v.map(s => {
                        const dex =
                          DEX_TYPE_PLATFORM[s.dexName ?? ''] ??
                          DEX_TYPE_PLATFORM.Econia;
                        return {
                          ...dex,
                          pct: s.percentage,
                        };
                      });
                    }),
                    _dex => _dex.name,
                  ),
                ),
                desc: `${routes[0].routeTaken
                  .map(s => `${s[0].fromToken.symbol}`)
                  .join('→')}→${
                  last(routes[0].routeTaken)?.[0].toToken.symbol ?? ''
                }`,
                receive: Number(_quote.toTokenAmount),
                minReceive: Number(_quote.minToTokenAmount),
                receiveValueUSD: `-`,
                slippagePct: slippage ?? 0,
                cost: Number(quote.fromTokenAmount),
                exchangeRate:
                  Number(_quote.toTokenAmount) /
                  Number(quote.fromTokenAmount),
                swap: withSendTxNotify(async (allowBorrow: boolean) => {
                  if (allowBorrow) {
                    throw new Error('Borrow Before Swap Coming Soon');
                  }

                  const {
                    function: func,
                    type_arguments,
                    arguments: args,
                  } = txData;

                  const res = await provider.sendCustomTx({
                    arguments: args,
                    type: '',
                    type_arguments,
                    function: func,
                  });

                  if (res.success) {
                    const fromAssetPrice =
                      tokenMap[fromAsset.id].priceUSDNum;
                    retryPost(() =>
                      axios.post(
                        'https://aries-serveless.vercel.app/api/trpc/swap.create?batch=1',
                        {
                          '0': {
                            json: {
                              transactionVersion: res.txId,
                              walletAddress,
                              fromTokenId: fromAsset.id,
                              toTokenId: toAsset.id,
                              fromAmount: amount,
                              fromUSDValue: amount * fromAssetPrice,
                            },
                          },
                        },
                      ),
                    );
                  }

                  await getBalanceHub()?.refresh();
                  return res;
                }),
              };
              // eslint-disable-next-line no-empty
            } catch (error) {}

            return undefined;
          }),
        );
      },
      getHasRoutesByPair: async (
        fromAsset?: Pick<Asset, 'id'>,
        toAsset?: Pick<Asset, 'id'>,
      ) => {
        try {
          if (!fromAsset?.id || !toAsset?.id) {
            return false;
          }

          const fromTokenAddress =
            ARIES_WCOIN_TO_FA_MAP[fromAsset.id] || fromAsset.id;
          const toTokenAddress =
            ARIES_WCOIN_TO_FA_MAP[toAsset.id] || toAsset.id;

          const quote = await panoraClient.ExactInSwapQuote({
            chainId: '1',
            fromTokenAddress,
            fromTokenAmount: '1',
            toTokenAddress,
            slippagePercentage: '0.5',
            numberOfRoutes: '1',
            integratorFeePercentage: '0',
            transactionPayload: true,
            toWalletAddress:
              '0x1b93898d2e9c559a62d8c4eb4bc0df86fef32bf9d23b27b8987bd3ed8837998a',
          });

          return !isEmpty(quote.quotes);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('[FIND ROUTES FAILED]', err);
          return false;
        }
      },
    };
  },
);
